
import {
    defineComponent,
    reactive,
    Ref,
    ref,
    onBeforeMount,
    onMounted,
    watch,
    nextTick,
} from 'vue';
import useProgramData from '@/hooks/claim/useProgramData';
import { getExtendInfo, getExtendInfoList } from '@/API/claim/claimParameter';
import {
    findAll,
    findAllByBu,
    getDefaultSettingList,
} from '@/API/claim/claimDocumentSample';
import { useStore } from 'vuex';
import useEventbus from '@/hooks/claim/useEventbus';
import { message } from 'ant-design-vue';
import { isArrayLike } from 'lodash';
interface DataItem {
    key: string;
    extend: string;
    extendValue: string;
    docNameCn: string;
    docNameEn: string;
    fileType: string;
    isRequired: string;
}

export default defineComponent({
    props: {
        itemOfferType: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        claimSettings: {
            type: Array,
            default: () => [],
            required: true,
        },
        bu: {
            type: String,
            default: '',
            required: true,
        },
        paramType: {
            type: String,
            default: '',
            required: true,
        },
    },
    setup(props) {
        const store = useStore();
        const { customOn } = useEventbus();
        const claimMethodData = store.state.claim.claimMethodData;
        const { progDesignId, offerTypeList } = useProgramData();
        onBeforeMount(() => {
            // 清空原有数据显示
            for (let i = 0; i < offerTypeList.value.length; i++) {
                offerTypeList.value[i].forms = [];
            }
        });
        // 回显数据获取
        const showData = (params: any) => {
            if (
                Array.isArray(store.state.claim.claimMethodData) &&
                store.state.claim.claimMethodData.length > 0 &&
                Array.isArray(params)
            ) {
                for (let j = 0; j < params.length; j++) {
                    for (let k = 0; k < offerTypeList.value.length; k++) {
                        if (
                            params[j]['progDesignOfferTypePathKey'] ===
                            offerTypeList.value[k]['progDesignOfferTypeId']
                        ) {
                            const obj = {};
                            obj['key'] = `${j}`;
                            obj['abbreviation'] = params[j]['abbreviation'];
                            obj['docDicManagementId'] =
                                params[j]['docDicManagementId'];
                            obj['docNameCn'] =
                                params[j]['docDicManagementNameCn'];
                            obj['docNameEn'] =
                                params[j]['docDicManagementNameEn'];
                            obj['docType'] = params[j]['docDicManagementType'];
                            obj['extend'] = params[j]['extendNameCn'];
                            obj['extendValue'] = params[j]['extendValue'];
                            obj['filePath'] = params[j]['filePath'];
                            obj['isRequired'] = params[j]['isRequired'];
                            obj['isVinbased'] = params[j]['isVinbased'];
                            obj['paraExtendInfoId'] =
                                params[j]['paraExtendInfoId'];
                            offerTypeList.value[k].forms.push(obj);
                        }
                    }
                }
            }
        };
        const extInfDataArr = ref<any[]>([]);
        const columns = [
            {
                title: 'Extend',
                dataIndex: 'extend',
                width: '200px',
                slots: { customRender: 'extend' },
            },
            {
                title: 'Extend Value',
                dataIndex: 'extendValue',
                width: '140px',
                slots: { customRender: 'extendValue' },
            },
            {
                title: 'Document Name(CN)',
                dataIndex: 'docNameCn',
                width: '200px',
                slots: { customRender: 'docNameCn' },
            },
            {
                title: 'Document Name(EN)',
                dataIndex: 'docNameEn',
                width: '140px',
                ellipsis: true,
                slots: { customRender: 'docNameEn' },
            },
            {
                title: 'File Type',
                dataIndex: 'fileType',
                width: '140px',
                slots: { customRender: 'fileType' },
            },
            {
                title: 'Is required',
                dataIndex: 'isRequired',
                width: '120px',
                slots: { customRender: 'isRequired' },
            },
            {
                title: 'Operation',
                dataIndex: 'operation',
                fixed: 'right',
                slots: { customRender: 'operation' },
            },
        ];
        const findAllData = ref<any[]>([]);
        const dataSource: Ref<DataItem[]> = ref([]);
        const extendInfoData = ref<any[]>([]);
        const offerInf = ref<any>({});
        //TODO 如果已保存数据了 这里需要在初始的时候就给出下拉菜单选项
        // const dogetExtendInfoList = () => {
        //     getExtendInfoList().then((res) => {
        //         const filterArr = ref<any[]>([]);
        //         res.forEach((item: any, index: any) => {
        //             if (item.fieldType === 'Enumeration') {
        //                 extInfDataArr.value.forEach((exitem, exindex) => {
        //                     if (exitem.fieldNameEn === item.fieldNameEn) {
        //                         let isRepeat = false;
        //                         filterArr.value.forEach((i, dex) => {
        //                             if (item.fieldNameEn === i.fieldNameEn) {
        //                                 isRepeat = true;
        //                             }
        //                         });
        //                         if (!isRepeat) {
        //                             filterArr.value.push(item);
        //                         }
        //                     }
        //                 });
        //             }
        //         });
        //         extendInfoData.value = filterArr.value;
        //         console.log('extendInfoData.value', extendInfoData.value);
        //     });
        // };
        const extInfData = (params: any) => {
            extInfDataArr.value = params;
            const enumItems: any[] = [];
            extInfDataArr.value.map((item: any) => {
                if (item.fieldType === 'Enumeration') {
                    // item.id = item.eligiblePeriodId;
                    // item.id = item.paraExtendInfoId;//! 修改了上面的绑定 id=>paraExtendInfoId
                    enumItems.push(item);
                    // console.log(268,item)
                }
            });
            extendInfoData.value = enumItems;
        };

        const getDefaultSettings = () => {
            if (props.claimSettings.length === 0) return;
            for (let i = 0; i < offerTypeList.value.length; i++) {
                const typeName = offerTypeList.value[i].progDesignOfferTypeName;
                offerTypeList.value[i].forms = findAllData.value.filter(
                    (doc: any) => {
                        return props.claimSettings[typeName].includes(
                            doc.docNameEn
                        );
                    }
                );
            }
        };

        // find All接口调用
        const findByBu = (bu: string) => {
            findAllByBu({ bu })
                .then((res) => {
                    for (let i = 0; i < res.length; i++) {
                        res[i]['docDicManagementId'] = res[i]['id'];
                        res[i]['isRequired'] = res[i]['isRequired'] ?? 'Y';
                    }
                    findAllData.value = res;
                })
                .then(() => {
                    getDefaultSettings();
                });
        };
        watch(
            [() => props.bu, () => props.claimSettings],
            ([newBu, newParams]: any[]) => {
                // if (newBu !== '' && Object.keys(newParams).length > 0) {
                console.log('props.bu', newBu);
                if (newBu !== '') {
                    findByBu(newBu);
                }
            },
            { immediate: true }
        );

        const onDocInfo = (val: string, item: any, index: number) => {
            if (item.forms.length === 1) {
                findAllData.value.forEach((data: any) => {
                    if (data.id === val) {
                        Object.keys(data).map(
                            (key) => (item.forms[index][key] = data[key])
                        );
                    }
                });
                return;
            }
            item.forms.forEach((i: any, ind: any) => {
                if (ind !== index) {
                    let iscanChange = true;
                    if (
                        i.extend === item.forms[index].extend &&
                        i.extendValue === item.forms[index].extendValue &&
                        i.id === val
                    ) {
                        iscanChange = false;
                        window.setTimeout(() => {
                            item.forms[index].docNameCn = '';
                        }, 300);
                        message.error('Duplicated document name.');
                    } else {
                        iscanChange = true;
                    }
                    if (iscanChange) {
                        findAllData.value.forEach((data: any) => {
                            if (data.id === val) {
                                Object.keys(data).map(
                                    (key) =>
                                        (item.forms[index][key] = data[key])
                                );
                            }
                        });
                    }
                }
            });
        };
        const onExtendInfo = (val: string, item: any, index: number) => {
            const params = { id: val };
            getExtendInfo({ params }).then((res: any) => {
                const {
                    enumValue,
                    fieldNameCn,
                    fieldNameEn,
                    fieldType,
                    id,
                    defaultValue,
                } = res;
                item.forms[index]['extend'] = fieldNameCn;
                item.forms[index]['paraExtendInfoId'] = id;
                if (fieldType === 'Enumeration') {
                    item.forms[index]['enumValue'] = enumValue.split(',');
                }
                item.forms[index]['extendValue'] = defaultValue;
            });
        };
        const getOfferType = (params: any) => {
            offerInf.value = params.offer;
        };
        const saveDocParams = () => {
            const queryParams = ref<any[]>([]);
            for (let j = 0; j < offerTypeList.value.length; j++) {
                const formsCur = offerTypeList.value[j].forms;
                // console.log('formsCur', formsCur);
                for (let k = 0; k < formsCur.length; k++) {
                    const obj = {
                        abbreviation: formsCur[k]['abbreviation'],
                        docDicManagementId: formsCur[k]['docDicManagementId'],
                        docDicManagementNameCn: formsCur[k]['docNameCn'],
                        docDicManagementNameEn: formsCur[k]['docNameEn'],
                        docDicManagementType: formsCur[k]['docType'],
                        extendNameCn: formsCur[k]['extend'],
                        extendValue: formsCur[k]['extendValue'],
                        filePath: formsCur[k]['filePath'],
                        isRequired: formsCur[k]['isRequired'],
                        isVinbased: formsCur[k]['isVinbased'],
                        paraExtendInfoId: formsCur[k]['paraExtendInfoId'],
                        progDesignId: progDesignId.value,
                        // progDesignOfferTypeId: offerTypeList.value[j]['progDesignOfferTypeId'],
                        // progDesignOfferTypePathKey: offerTypeList.value[j]['progDesignOfferTypePathKey'],
                        // progDesignOfferTypeName: offerTypeList.value[j]['progDesignOfferTypeName'],
                        progDesignOfferTypeId: (props as any).itemOfferType[
                            offerTypeList.value[j]['progDesignOfferTypeId']
                        ]['offerTypeId'],
                        progDesignOfferTypePathKey: (props as any)
                            .itemOfferType[
                            offerTypeList.value[j]['progDesignOfferTypeId']
                        ]['offerTypePathKey'],
                        progDesignOfferTypeName: (props as any).itemOfferType[
                            offerTypeList.value[j]['progDesignOfferTypeId']
                        ]['offerTypeName'],
                    };
                    if (
                        obj.docDicManagementNameCn !== '' ||
                        obj.docDicManagementNameEn !== '' ||
                        obj.extendNameCn !== '' ||
                        obj.extendValue !== '' ||
                        obj.isRequired !== ''
                    ) {
                        queryParams.value.push(obj);
                    }
                }
            }
            const option = {
                name: 'claimParaSetupSupportingDocVos',
                rawData: queryParams.value,
            };
            if (queryParams.value.length > 0) {
                store.commit('claim/updateSaveParamsData', option);
            }
        };

        onMounted(() => {
            customOn('showClaimParaSetupSupportingData', showData);
            customOn('saveDocParams', saveDocParams);
            customOn('ExtendInformationNowData', extInfData);
            customOn('designData', getOfferType);
        });
        const onDelete = (item: any, key: string, index: number) => {
            const arr = [];
            for (let i = 0; i < item.forms.length; i++) {
                if (item.forms[i]['key'] !== key) {
                    arr.push(item.forms[i]);
                }
            }
            item.forms = arr;
        };
        const handleAdd = (item: any) => {
            const key = item.forms.length + 1;
            const newData = {
                key: `${key}`,
                extend: '',
                extendValue: '',
                docNameCn: '',
                docNameEn: '',
                fileType: '',
                isRequired: 'Y',
            };
            item.forms.push(newData);
        };
        return {
            getOfferType,
            extInfDataArr,
            extInfData,
            progDesignId,
            findAllData,
            extendInfoData,
            offerTypeList,
            columns,
            onDelete,
            onDocInfo,
            onExtendInfo,
            handleAdd,
            saveDocParams,
            dataSource,
            showData,
            claimMethodData,
        };
    },
});
