
import {
    defineComponent,
    nextTick,
    onMounted,
    reactive,
    Ref,
    ref,
    watch,
} from 'vue';
import useProgramData from '@/hooks/claim/useProgramData';
import { findAllByBu } from '@/API/claim/claimDocumentSample';
import { useStore } from 'vuex';
import useEventbus from '@/hooks/claim/useEventbus';
import { message } from 'ant-design-vue';
interface DataItem {
    key: string;
    docNameCn: string;
    docNameEn: string;
    docType: string;
    isRequired: string;
}

export default defineComponent({
    props: {
        bu: {
            type: String,
            default: '',
            required: true,
        },
        docSettings: {
            type: Array,
            default: () => [],
            required: true,
        },
    },
    setup(props) {
        const store = useStore();
        const claimMethodData = store.state.claim.claimMethodData;
        const { customOn } = useEventbus();
        // programId ,offerType数据获取
        const { progDesignId } = useProgramData();
        const columns = [
            {
                title: 'Document Name(CN)',
                dataIndex: 'docNameCn',
                width: '200px',
                slots: { customRender: 'docNameCn' },
            },
            {
                title: 'Document Name(EN)',
                dataIndex: 'docNameEn',
                width: '180px',
                ellipsis: true,
                slots: { customRender: 'docNameEn' },
            },
            {
                title: 'File Type',
                dataIndex: 'docType',
                width: '140px',
                slots: { customRender: 'docType' },
            },
            {
                title: 'Is required',
                dataIndex: 'isRequired',
                width: '140px',
                slots: { customRender: 'isRequired' },
            },
            {
                title: 'Operation',
                dataIndex: 'operation',
                slots: { customRender: 'operation' },
            },
        ];
        const findAllData = ref<any[]>([]);
        const dataSource: Ref<DataItem[]> = ref([]);

        //* 选择document name时 要判断是否重复
        const selectedSupportingDoucmentType: any[] = [];
        // 回显数据获取
        const showData = (parameters: any) => {
            const params = parameters.supportingDocument;
            const params2 = parameters.fileNamingRule;
            if (
                // Array.isArray(store.state.claim.claimMethodData) &&
                // store.state.claim.claimMethodData.length > 0 &&
                Array.isArray(params)
            ) {
                dataSource.value = [];
                for (let j = 0; j < params.length; j++) {
                    // if (
                    //     params[j]['progDesignOfferTypeId'] ===
                    //     dataSource.value['progDesignOfferTypeId']
                    // ) {
                    const obj: any = {};
                    obj['abbreviation'] = params[j]['abbreviation'];
                    obj['docDicManagementId'] = params[j]['docDicManagementId'];
                    obj['docNameCn'] = params[j]['docDicManagementNameCn'];
                    obj['docNameEn'] = params[j]['docDicManagementNameEn'];
                    obj['docType'] = params[j]['docDicManagementType'];
                    obj['extend'] = params[j]['extendNameCn'];
                    obj['extendValue'] = params[j]['extendValue'];
                    obj['filePath'] = params[j]['filePath'];
                    obj['isRequired'] = params[j]['isRequired'];
                    obj['isVinbased'] = params[j]['isVinbased'];
                    obj['paraExtendInfoId'] = params[j]['paraExtendInfoId'];
                    obj['progDesignId'] = params[j]['progDesignId'];
                    //! 融合file Naming Rule的数据
                    obj['rule'] = params2[j]['rule'];
                    obj['fieldNameEn'] = params2[j]['valueOfExtend'];
                    dataSource.value.push(obj);
                    selectedSupportingDoucmentType.push(obj['docNameCn']);
                    // }
                }
                for (let i = 0; i < dataSource.value.length; i++) {
                    dataSource.value[i]['key'] = `${i}`;
                }
            }
        };

        const getDefaultSettings = () => {
            // for (let i = 0; i < offerTypeList.value.length; i++) {
            //     const typeName = offerTypeList.value[i].progDesignOfferTypeName;
            //     offerTypeList.value[i].forms = findAllData.value.filter(
            //         (doc: any) => {
            //             return props.claimSettings[typeName].includes(
            //                 doc.docNameEn
            //             );
            //         }
            //     );
            // }
            if (props.docSettings.length === 0) return;
            dataSource.value = findAllData.value.filter((doc: any) => {
                return props.docSettings.includes(doc.docNameEn);
            });
        };
        // TODO 还得带claim method为dealer claim的program category吧？然后通过这个category过滤document是不
        // find All接口调用
        const findByBu = (bu: string) => {
            findAllByBu({ bu })
                .then((res) => {
                    for (let i = 0; i < res.length; i++) {
                        res[i]['docDicManagementId'] = res[i]['id'];
                        res[i]['isRequired'] = 'Y';
                    }
                    findAllData.value = res;
                })
                .then(() => {
                    getDefaultSettings();
                });
        };
        watch(
            [() => props.bu, () => props.docSettings],
            ([newBu, newSettings]: any[]) => {
                if (newBu !== '') {
                    findByBu(newBu);
                }
            }
        );

        const onDocInfo = (val: string, index: number) => {
            if (selectedSupportingDoucmentType.includes(val)) {
                message.error('Duplicated document name.');
                nextTick(() => {
                    dataSource.value[index].docNameCn = '';
                });
                selectedSupportingDoucmentType.splice(index, 1);
                return;
            } else {
                selectedSupportingDoucmentType.splice(index, 1, val);
            }
            findAllData.value.forEach((data: any) => {
                if (data.id === val || data.docNameCn === val) {
                    Object.keys(data).map(
                        (key) => (dataSource.value[index][key] = data[key])
                    );
                }
            });
        };
        const onDelete = (key: string, index: number) => {
            selectedSupportingDoucmentType.splice(index, 1);
            const arr = [];
            for (let i = 0; i < dataSource.value.length; i++) {
                if (dataSource.value[i]['key'] !== key) {
                    arr.push(dataSource.value[i]);
                }
            }
            dataSource.value = arr;
        };
        const saveDocSupportingDocVosParams = () => {
            const queryParams = ref<any[]>([]);
            for (let k = 0; k < dataSource.value.length; k++) {
                const currentName = dataSource.value[k]['docNameCn'];
                if (currentName !== '') {
                    const obj = {
                        abbreviation: dataSource.value[k]['abbreviation'],
                        docDicManagementId:
                            dataSource.value[k]['docDicManagementId'],
                        docDicManagementNameCn:
                            dataSource.value[k]['docNameCn'],
                        docDicManagementNameEn:
                            dataSource.value[k]['docNameEn'],
                        docDicManagementType: dataSource.value[k]['docType'],
                        filePath: dataSource.value[k]['filePath'],
                        isRequired: dataSource.value[k]['isRequired'],
                        isVinbased: dataSource.value[k]['isVinbased'],
                        progDesignId: progDesignId.value,
                    };
                    queryParams.value.push(obj);
                }
            }
            const option = {
                name: 'docParaSetupSupportingDocVos',
                rawData: queryParams.value,
            };
            store.commit('claim/updateSaveParamsData', option);
        };
        onMounted(() => {
            customOn(
                'saveDocSupportingDocVosParams',
                saveDocSupportingDocVosParams
            );
            customOn('showDocParaSetupSupportingData', showData);
        });

        const handleAdd = () => {
            const key = dataSource.value.length + 1;
            const newData = {
                key: `dsd${key}`,
                docNameCn: '',
                docNameEn: '',
                docType: '',
                isRequired: 'Y',
            };
            dataSource.value.push(newData);
        };
        watch(
            dataSource,
            (newVal) => {
                console.log('newVal._rawValue', newVal);
                store.commit('claim/updateDocDocumentsData', newVal);
            },
            { deep: true }
        );
        return {
            progDesignId,
            findAllData,
            columns,
            onDelete,
            onDocInfo,
            handleAdd,
            saveDocSupportingDocVosParams,
            dataSource,
            showData,
            claimMethodData,
        };
    },
});
