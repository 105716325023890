
import { computed, defineComponent, Ref, ref, onMounted } from 'vue';
import {
    categoryList,
    getExtendInfo,
    getExtendInfoList,
    categoryMappingList,
} from '@/API/claim/claimParameter';
import useProgramData from '@/hooks/claim/useProgramData';
import { useStore } from 'vuex';
import useEventbus from '@/hooks/claim/useEventbus';
import { message } from 'ant-design-vue';
interface DataItem {
    fieldNameEn: string;
    fieldNameCn: string;
    fieldType: number | string;
    enumValue: number | string;
    minValue: number | string;
    maxValue: number | string;
    defaultValue: string;
    isMandatory: string;
    key: string;
    id?: string;
    paraExtendCategoryId?: string;
    paraExtendInfoId?: string;
}
//TODO 扩展信息不能重复
export default defineComponent({
    setup() {
        const store = useStore();
        const { customOn, customEmit } = useEventbus();
        const claimMethodData = store.state.claim.claimMethodData;
        const columns = [
            {
                title: 'FieldName',
                dataIndex: 'fieldNameEn',
                width: '200px',
                slots: { customRender: 'fieldName' },
            },
            {
                title: 'FieldNameCN',
                dataIndex: 'fieldNameCn',
                width: '140px',
                slots: { customRender: 'fieldNameCN' },
            },
            {
                title: 'Field Type',
                dataIndex: 'fieldType',
                width: '140px',
                slots: { customRender: 'filedType' },
            },
            {
                title: 'Enum Value',
                dataIndex: 'enumValue',
                width: '140px',
                slots: { customRender: 'enumValue' },
            },
            {
                title: 'Min Value',
                dataIndex: 'minValue',
                width: '140px',
                slots: { customRender: 'minValue' },
            },
            {
                title: 'Max Value',
                dataIndex: 'maxValue',
                width: '140px',
                slots: { customRender: 'maxValue' },
            },
            {
                title: 'Default Value',
                dataIndex: 'defaultValue',
                width: '140px',
                slots: { customRender: 'defaultValue' },
            },
            {
                title: 'Is Mandatory',
                dataIndex: 'isMandatory',
                width: '140px',
                slots: { customRender: 'isMandatory' },
            },
            {
                title: 'Operation',
                fixed: 'right',
                width: '100px',
                slots: { customRender: 'operation' },
            },
        ];
        const extendInfoData = ref([]);
        const categoryData = ref([]);
        // categoryList数据接口调用
        categoryList().then((res) => {
            categoryData.value = res;
        });
        const dataSource: Ref<DataItem[]> = ref([]);
        // 回显数据获取
        getExtendInfoList().then((res) => {
            extendInfoData.value = res;
        });
        const showData = (params: any) => {
            getExtendInfoList().then((res) => {
                extendInfoData.value = res;
                if (store.state.claim.extendInfoData.length === 0) {
                    store.commit('claim/updateExtendInfoData', res);
                }
                if (
                    Array.isArray(store.state.claim.claimMethodData) &&
                    store.state.claim.claimMethodData.length > 0 &&
                    Array.isArray(params)
                ) {
                    dataSource.value = params;
                    // 通过paraExtendInfoId ，找到相应id对应的extendInfoData中fieldNameEn
                    for (let i = 0; i < dataSource.value.length; i++) {
                        for (let j = 0; j < extendInfoData.value.length; j++) {
                            if (
                                dataSource.value[i]['paraExtendInfoId'] ===
                                extendInfoData.value[j]['id']
                            ) {
                                dataSource.value[i]['EPeriodId'] =
                                    extendInfoData.value[j]['fieldNameEn'];
                                dataSource.value[i]['key'] = `${i}`;
                            }
                        }
                    }
                }
                customEmit('ExtendInformationNowData', dataSource.value);
            });
        };
        // programId ,offerType数据获取
        const { progDesignId } = useProgramData();
        const categoryVal = ref<string>('');
        const onDelete = (key: string) => {
            dataSource.value = dataSource.value.filter(
                (item) => item.key !== key
            );
            customEmit('ExtendInformationNowData', dataSource.value);
        };
        const onChange = (
            value: any,
            option: any,
            record: any,
            index: number
        ) => {
            // const params = { id: record.id };
            const params = { id: option.value };
            let isCanAdd = true;
            dataSource.value.forEach((i: any, ind: any) => {
                if (ind !== index && value === i.id) {
                    //! 两个parameter都会有这个问题
                    message.error('Duplicated field name.');
                    dataSource.value[index]['EPeriodId'] = '';
                    dataSource.value[index].id = '';
                    isCanAdd = false;
                }
            });
            if (isCanAdd) {
                getExtendInfo({ params }).then((res: any) => {
                    dataSource.value[index] = Object.assign(
                        {
                            // paraExtendInfoId: record.id,
                            paraExtendInfoId: option.value,
                            paraExtendCategoryId: '',
                            EPeriodId: res.fieldNameEn,
                        },
                        res
                    );
                    customEmit('ExtendInformationNowData', dataSource.value);
                });
            }
        };
        const count = computed(() => dataSource.value.length + 1);
        const handleAdd = () => {
            const newData = {
                key: `${count.value}`,
                fieldNameEn: '',
                fieldNameCn: '',
                fieldType: '',
                enumValue: '',
                minValue: '',
                maxValue: '',
                defaultValue: '',
                isMandatory: '',
            };
            dataSource.value.push(newData);
        };
        const addAllHandle = () => {
            const params = { id: categoryVal.value };
            categoryMappingList({ params }).then((res) => {
                if (dataSource.value.length !== 0) {
                    for (let i = 0; i < res.length; i++) {
                        let flag = true;
                        for (let j = 0; j < dataSource.value.length; j++) {
                            if (
                                res[i]['claimParaExtendInfo']['fieldNameEn'] ===
                                dataSource.value[j]['fieldNameEn']
                            ) {
                                flag = false;
                            }
                        }
                        customEmit(
                            'ExtendInformationNowData',
                            dataSource.value
                        );
                        if (flag) {
                            res[i].claimParaExtendInfo[
                                'key'
                            ] = `${count.value}`;
                            const obj = {
                                paraExtendCategoryId:
                                    res[i].paraExtendCategoryId,
                                paraExtendInfoId: res[i].paraExtendInfoId,
                                id: res[i].claimParaExtendInfo['fieldNameEn'],
                                EPeriodId:
                                    res[i].claimParaExtendInfo['fieldNameEn'],
                            };
                            dataSource.value.push(
                                Object.assign(obj, res[i].claimParaExtendInfo)
                            );
                        }
                    }
                } else {
                    res.map((item: any) => {
                        if (!item.claimParaExtendInfo['key'])
                            item.claimParaExtendInfo['key'] = `${count.value}`;
                        item.claimParaExtendInfo.id =
                            item.claimParaExtendInfo.fieldNameEn;
                        item.claimParaExtendInfo.EPeriodId =
                            item.claimParaExtendInfo.fieldNameEn;
                        item.claimParaExtendInfo.paraExtendCategoryId =
                            item.paraExtendCategoryId;
                        item.claimParaExtendInfo.paraExtendInfoId =
                            item.paraExtendInfoId;
                        dataSource.value.push(item.claimParaExtendInfo);
                        customEmit(
                            'ExtendInformationNowData',
                            dataSource.value
                        );
                    });
                }
            });
        };
        // 保存此模块数据到vuex
        const saveParamsExtend = () => {
            const queryParams = ref<any[]>([]);
            dataSource.value.forEach((item) => {
                queryParams.value.push({
                    defaultValue: item.defaultValue,
                    eligiblePeriodId: item['EPeriodId'],
                    enumValue: item.enumValue,
                    fieldNameCn: item.fieldNameCn,
                    fieldNameEn: item.fieldNameEn,
                    fieldType: item.fieldType,
                    isMandatory: item.isMandatory,
                    maxValue: item.maxValue,
                    minValue: item.minValue,
                    paraExtendCategoryId: item.paraExtendCategoryId,
                    paraExtendInfoId: item.paraExtendInfoId,
                    progDesignId: progDesignId.value,
                });
            });
            const option = {
                name: 'claimClaimParaSetupExtendInfoVos',
                rawData: queryParams.value,
            };
            store.commit('claim/updateSaveParamsData', option);
        };
        onMounted(() => {
            customOn('showClaimParaSetupExtendData', showData);
            customOn('saveParamsExtend', saveParamsExtend);
        });
        // // 监听saveParamsExtend
        // customOn('saveParamsExtend', saveParamsExtend)
        return {
            categoryVal,
            categoryData,
            extendInfoData,
            columns,
            onDelete,
            handleAdd,
            addAllHandle,
            onChange,
            dataSource,
            count,
            // saveParamsExtend,
            showData,
            claimMethodData,
        };
    },
});
